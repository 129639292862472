h4.heading {
  margin: 1%;
  padding: 10px;
  width: 50%;
}

.details-container {
  display: flex;
  flex-direction: column;
  padding: 20px 3%;
  .single {
    display: flex;
    width: 80%;
    height: 30px;
    padding: 5px;
    margin-bottom: 5px;
    p.detail {
      font: {
        size: 15px;
      }
      width: 70%;
    }
    p.label {
      color: #6c757d;
      font-weight: 600;
      width: 30%;
    }
  }
  .break {
    height: 1px;
    margin: 15px;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
  .input-details {
    flex-direction: column;
    margin-bottom: 25px;
    div {
      @extend .single;
    }
  }
}
.sub-heading {
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 600;
}

.capitalize{
  text-transform: capitalize;
}