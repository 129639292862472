.documents>.card {
  border: 0px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  min-height: 270px !important;

  .date-picker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    border: 1px solid #707781;
    border-radius: 6px;
    padding: 2px 12px;
    margin-top: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.12s;

    i {
      font-size: 11px;
    }

    .form-control {
      border: none;
      margin-left: auto;
      height: 25px;
      width: 90%;
      font-size: 13px;
      padding: 0px;
      cursor: inherit;
    }

    .form-control:focus {
      box-shadow: none;
    }
  }

  .picker-focused {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
    border: none;
  }
}

.uploaded-image {
  height: 120px;
  width: 100%;
  border-radius: 7px;
  border: 2px solid #CCCCCC;
  overflow: hidden;
}
.uploaded-image {
  height: 120px !important;
  width: 100%;
  border-radius: 7px;
  overflow: hidden;
  border: 2px solid #CCCCCC;
  position: relative;
  top: 0px;
  object-fit: contain;

  img {
    width: 100% !important;
    height: 120px !important;
  }
}
.upload-button {
  margin-top: 0.5rem !important;

  >button {
    width: 40px !important;
  }
}

.react-pdf__Page__canvas {
  height: 175px !important;
  object-fit: contain !important;
  width: 100% !important;
}

.document-container {
  border: 0.5px solid lightgrey;
}

.uploaded-image-container {
  margin-bottom: 20px;
  position: relative;
  .fullscreen-icon {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    color: #F4511D;
    padding: 0px 6px;
    border-radius: 20px;
    position: absolute;
    top: 80px;
    right: 10px;
  }

  .delete-icon {
    color: #cc0000;
    right: unset;
    left: 10px;
  }

  .approve-btn,
  .reject-btn {
    padding: 0px 6px;
    border-radius: 20px;
    color: #FFFFFF;
    height: 30px;
    width: 30px;
  }

  .approve-btn,
  .badge-success {
    background-color: #27EA81;
  }

  .reject-btn,
  .badge-danger {
    background-color: #D0021B;
  }

  .approve-icon {
    position: absolute;
  }

  .badge {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
  }
}

.source {
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: end;
}