.single-order-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 98%;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 25px;
    margin-bottom: 20px;
    h4 {
      font-weight: bold;
      margin-top: auto !important;
      margin-bottom: 0px;
    }
    button.export {
      background-color: #fff;
      border: 1px solid rgba(19, 38, 50, 0.5);
      border-radius: 5px;
      padding: 0px 50px;
      height: 34px;
      color: #000;
      font-size: 14px;
      line-height: 16px;
      margin-left: auto;
    }
    button.refund,
    button.receipt,
    button.user-comm-button,
    button.finished {
      margin-left: 20px;
      padding: 0px 25px !important;
      height: 34px;
      font-size: 15px;
      margin-bottom: 10px;
    }
    button.refund {
      margin-left: 0px !important;
    }
  }
  .order-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 3%;
    margin: auto;
    padding: 3% 3%;
    background-color: #fefefe;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    color: #122632;
    .basic-order-details {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .detail {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        .label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 2px;
        }
        .info {
          line-height: 23px;
          font: {
            size: 20px;
            weight: bold;
          }
        }
      }
      .status-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        span.fleet-indicator {
          background-color: #f4511d;
          color: #fff;
          padding: 5px 20px;
          border-radius: 10px;
          font-size: 16px;
          line-height: 20px;
          margin-left: 15px;
        }
        .status-label {
          display: flex;
          height: 32px;
          align-items: center;
          border-radius: 10px;
          padding: 0px 20px;
          font-size: 14px;
          line-height: 16px;
        }
        .delivery {
          @extend .theme-orange;
          background-color: rgba(244, 81, 29, 0.2);
          margin-bottom: 5px;
        }
        .status {
          text-transform: capitalize;
        }
      }
    }
    .client-details-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .single-section {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 19px;
        .client-name {
          margin-bottom: 8px;
          margin-top: 2px;
          line-height: 23px;
          font: {
            size: 20px;
            weight: bold;
          }
        }
        .client-address {
          width: 55%;
          @extend .label;
        }
        .client-phone {
          @extend .client-name;
        }
        .item-count {
          font-weight: bold;
        }
      }
      .single-section:last-child {
        align-items: flex-end;
      }
    }
    .order-details-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 0px 0px;
      .single-plate {
        padding-bottom: 5px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        .basic-plate-details {
          display: flex;
          width: 100%;
          padding: 5px 0px;
          align-items: center;
          margin: 10px 0;
          .dish-count {
            width: 5.7%;
            letter-spacing: 2px;
            @extend .label;
          }
          .name {
            width: 80%;
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
          }
          .dish-price {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            font-weight: bold;
            letter-spacing: 0.5px;
          }
        }
        .dish-desc {
          flex: 1;
          overflow-wrap: break-word;
          margin-left: 5.7%;
          .variants {
            flex: 1;
            margin-left: 6px;
            margin-top: 10px;
            .variants-label {
              background: rgba(244, 81, 29, 0.2);
              color: #f4511d;
              @extend .color-label;
            }
            .variant {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .name {
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 3px;
              }
              .amount {
                font-weight: bold;
                letter-spacing: 0.5px;
              }
            }
          }
          .notes {
            width: 85%;
            margin: 15px 0px 12px 6px;
            .notes-label {
              background: rgba(255, 199, 1, 0.2);
              color: #ffc107;
              @extend .color-label;
            }
            .note {
              font-size: 14px;
              line-height: 16px;
              margin-bottom: 3px;
              color: rgba($color: #22263a, $alpha: 0.6);
              line-height: 14px;
              font : {
                style: italic;
                weight: normal;
                size: 12px;
              }
            }
          }
        }
      }
      .total-breakdown-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5px 0px 0px;
        padding-top: 10px;
        .subtotal-section,
        .delivery-fee-section,
        .delivery-tax-section,
        .discount-section,
        .tips-section,
        .tax-section {
          font-weight: bold;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 16px;
          line-height: 19px;
          padding: 2px 0px;
          color: #122632;
          opacity: 0.5;
        }
        .footnote-and-promo-section {
          border-top: solid 1px rgba(0, 0, 0, 0.2);
          padding-top: 12px;
          margin-top: 12px;
          .footnote-label {
            background: rgba(255, 199, 1, 0.2);
            color: #ffc107;
            @extend .color-label;
          }
          .footnote {
            line-height: 14px;
            color: #6c7880;
            overflow-wrap: break-word;
            width: 82%;
            margin-top: 8px;
            margin-bottom: 15px;
            font: {
              size: 12px;
              style: italic;
            }
          }
          .promo-code-label {
            background: rgba(0, 191, 165, 0.2);
            color: #00bfa5;
            @extend .color-label;
          }
          .promo-code {
            line-height: 19px;
            color: #6c7880;
            font: {
              size: 16px;
              style: italic;
              weight: bold;
            }
          }
        }
        .total-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @extend .theme-orange;
          padding: 10px 0px;
          margin-top: 10px;
          border-bottom: solid 3px rgba(0, 0, 0, 0.6);
          border-top: solid 1px rgba(0, 0, 0, 0.2);
          font-size: 20px;
          line-height: 23px;
        }
        .amount {
          letter-spacing: 0.5px;
        }
      }
    }
    .review-details-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .label {
        margin-bottom: 10px;
      }
      .single-review {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        img {
          margin-right: 12px;
          height: 30px;
        }
        .reviewee-details {
          width: 90%;
          font-size: 14px;
          line-height: 16px;
          color: #3b3b3b;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .reviewee-name {
            text-decoration-line: underline;
          }
          .reviewee-type {
            font-weight: 500;
            margin-top: 2px;
          }
          .review-text {
            margin-top: 6px;
            opacity: 0.6;
            font-style: italic;
          }
        }
        .review-rating {
          max-width: 311px !important;
          min-width: fit-content;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .bonus-earnings-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .label {
        margin-bottom: 8px;
      }
      .buttons-container {
        button {
          min-width: 65px;
        }
      }
      .single-bonus-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0px;
        font-weight: bold;
        input {
          // display: none;
          max-width: 60vw !important;
          min-width: 60vw !important;
          height: 30px;
          margin-bottom: 4px;
        }
        ::-webkit-inner-spin-button {
          appearance: none;
        }
        .amount {
          letter-spacing: 0.5px;
        }
      }
    }
    .route-information-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 16px;
      line-height: 19px;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .label {
        margin-bottom: 8px;
      }
      .distance-section,
      .duration-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2px 0px;
        font-weight: bold;
      }
    }
    .not-accepted-by-driver-details-section {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      padding: 15px 0px;
      width: 100%;
      .section-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .drivers {
        display: flex;
        flex-wrap: wrap;
        .driver {
          display: flex;
          align-items: center;
          border: 1px solid lightgrey;
          padding: 10px;
          border-radius: 10px;
          margin-top: 10px;
          margin-right: 10px;
          max-width: 400px;
          .remove-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 20px;
            width: 20px;
            font-size: 25px;
            border-radius: 50%;
            background-color: red;
            color: white;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .car-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            height: 30px;
            width: 30px;
            font-size: 25px;
            border-radius: 50%;
            background-color: rgba($color: #6C7880, $alpha: 0.15);
            margin-right: 10px;
          }
          .detail {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            font-size: 16px;
          }
        }
      }
    }
    .driver-details-section {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      padding: 15px 0px;
      width: 100%;
      .driver-details-fields-container,
      .driver-details-section-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .driver-details-section-header {
        align-items: center;
        margin-bottom: 10px;
      }
      .driver-details-fields-container {
        > .driver-name-and-date-container {
          display: flex !important;
          align-items: center;
          > div > i {
            background-color: rgb(207, 203, 203);
            font-size: 15px;
            padding: 10px 9px;
            border-radius: 50%;
            margin-right: 10px;
          }
          > div {
            > .driver-name {
              font-size: 20px;
              line-height: 23px;
              text-decoration-line: underline;
              @extend .theme-orange;
            }
            > .driver-joined {
              font-size: 16px;
              line-height: 19px;
              color: #6c7880;
            }
          }
        }
        > .driver-phone-and-earnings-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          > .driver-phone,
          > .driver-earnings {
            font-size: 20px;
            line-height: 23px;
            font-weight: bold;
          }
          > .driver-earnings {
            letter-spacing: 0.5px;
          }
          > .driver-phone {
            margin-bottom: 8px;
          }
        }
      }
    }
    .assign-driver-section {
      display: flex;
      flex-direction: column;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      padding: 15px 0px;
      width: 100%;
      .section {
        display: flex;
        margin-top: 8px;
        > div > input {
          width: 25vw;
        }
        button {
          margin-left: 20px;
          min-width: 75px;
        }
      }
    }
    .toll-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .header {
        display: flex;
        justify-content: space-between;
        min-width: 100%;
        padding: 0px;
        margin-bottom: 10px;
        .label {
          margin-bottom: 8px;
        }
      }
      .toll-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2px 0px;
        font-weight: bold;
        .amount {
          letter-spacing: 0.5px;
        }
      }
    }
    .transaction-info-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .label {
        margin-bottom: 8px;
      }
      .transaction-section {
        font-weight: bold;
        .transaction-type,
        .transaction-id {
          display: flex;
          justify-content: space-between;
        }
        .card-details {
          .card-number {
            display: flex;
            span:first-of-type {
              display: flex;
              margin-right: auto;
            }
            img {
              height: 25px;
              margin-right: 10px;
            }
          }
        }
        .card-expiry {
          display: flex;
          justify-content: space-between;
        }
      }
      .no-transaction {
        @extend .label;
        margin-top: 10px;
      }
    }
    .refunds-container {
      width: 100%;
      padding: 15px 0px 0px;
      font-size: 16px;
      line-height: 19px;
      .label {
        margin-bottom: -8px;
      }
      .refunds {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .single-transaction-container {
          .single-refund {
            padding-bottom: 15px;
            border-bottom: solid 1px rgba(0, 0, 0, 0.2);
            .refund-header {
              display: flex;
              justify-content: space-between;
              margin: 15px 0px 10px;
              .refund-type {
                width: fit-content;
                line-height: 19px;
                font-weight: bold;
              }
              .total-amount {
                font-size: 16px;
                padding: 4px 10px;
                line-height: 19px;
                color: #d0021b;
                background: rgba(208, 2, 27, 0.2);
                border-radius: 10px;
              }
            }
            .refund-item {
              display: flex;
              flex-direction: column;
              min-width: 100%;
              .item-details {
                display: flex;
                align-items: center;
                padding: 10px 0;
                .dish-quantity {
                  width: 15%;
                  color: #6c7880;
                }
                > .plate-details {
                  width: 85%;
                  > .dish-details {
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    max-width: 100% !important;
                    padding: 0;
                    margin: 0;
                  }
                  .variant-details {
                    .variants-label {
                      @extend .color-label;
                      background: rgba(244, 81, 29, 0.2);
                      color: #f4511d;
                      margin-bottom: 6px !important;
                    }
                    .variant {
                      display: flex;
                      justify-content: space-between;
                      font-size: 14px;
                      line-height: 16px;
                      margin-bottom: 2px;
                      .variant-price {
                        font-weight: bold;
                      }
                    }
                  }
                }
              }
              .reason-details {
                margin-top: 20px;
                .reason-label {
                  @extend .color-label;
                  color: #d0021b;
                  background: rgba(208, 2, 27, 0.2);
                  margin-bottom: 4px !important;
                }
                .reason {
                  line-height: 19px;
                  color: #6c7880;
                  font: {
                    style: italic;
                    weight: normal;
                    size: 12px;
                  }
                }
              }
            }
          }
          .full-refund-container {
            border-bottom: solid 1px rgba(0, 0, 0, 0.2);
            padding: 15px 0px;
            .full-refund-header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              .refund-type {
                line-height: 19px;
                font-size: 16px;
                font-weight: bold;
              }
              .refund-amount {
                font-size: 16px;
                padding: 4px 10px;
                line-height: 19px;
                color: #d0021b;
                background: rgba(208, 2, 27, 0.2);
                border-radius: 10px;
              }
            }
            .reason-label {
              @extend .color-label;
              color: #d0021b;
              background: rgba(208, 2, 27, 0.2);
              margin-bottom: 4px !important;
            }
            .reason {
              line-height: 19px;
              color: #6c7880;
              font: {
                style: italic;
                weight: normal;
                size: 12px;
              }
            }
          }
          .refunded-by-section {
            display: flex;
            flex-direction: column;
            padding: 15px 0px;
            border-bottom: solid 3px rgba(0, 0, 0, 0.6);
            .refunded-by-header {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #000000;
              opacity: 0.5;
              margin-bottom: 8px;
            }
            .refunded-by-details {
              display: flex;
              width: fit-content;
              align-items: center;
              div.avatar > i {
                padding: 8px;
                padding-left: 0px;
                font-size: 28px;
              }
              .info {
                background-color: floralwhite;
                .admin-name {
                  font-weight: bold;
                  font-size: 20px;
                  line-height: 23px;
                  text-decoration-line: underline;
                  color: #f4511d;
                }
                .admin-created-at {
                  font-size: 16px;
                  line-height: 19px;
                  color: #6c7880;
                }
              }
            }
          }
        }
      }
    }
    .notes-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .notes-header,
      .communication-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .empty-notes-message {
        @extend .label;
      }
    }
    .order-updates-section {
      display: flex;
      flex-direction: column;
      padding: 15px 0px;
      .label {
        margin-bottom: 10px;
      }
      .single-update {
        margin-bottom: 2px;
      }
    }
  }
}
.fixed {
  overflow: hidden;
  max-height: 100% !important;
}
.label {
  color: rgba(0, 0, 0, 0.5);
}
.theme-orange {
  color: #f4511d;
}
.paid,
.delivered,
.finished,
.deferred {
  background-color: #22b65d;
  color: #fff;
}
.received,
.cooking,
.pickedup {
  background-color: #ff6b1c;
  color: #fff;
}
.review,
.refundpartial,
.refund {
  background-color: #ffa250;
  color: #fff;
}
.unpaid {
  background-color: #f35151;
  color: #fff;
}
.cancelled {
  background-color: #e1e1e1;
  color: #000;
}
.color-label {
  border-radius: 10px;
  width: fit-content;
  padding: 4px 10px;
  margin-bottom: 8px;
  line-height: 14px;
  font: {
    size: 12px;
    weight: bold;
  }
}
.delete-icon {
  .spinner-border {
    height: 0.85rem;
    width: 0.85rem;
    border-width: 2px;
    color: #ffffff !important;
    padding-right: 10px;
  }
}
.btn-min-width {
  min-width: 100px;
}
.refunded-strikethrough {
  font-style: italic;
  text-decoration: line-through;
  color: rgba($color: #22263a, $alpha: 0.6);
}

@media only screen and (max-width: 577px) {
  .single-order-container {
    padding: 0 !important;
    margin-top: -15px;
    .order-details-container {
      .assign-driver-section {
        .section {
          display: block;
          button {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .header {
    flex-direction: column !important;
    height: fit-content !important;
    .buttons-container {
      display: flex;
      margin-top: 15px;
      flex-wrap: wrap;
      button.refund,
      button.receipt,
      button.user-comm-button,
      button.finished {
        padding: 0 15px !important;
        margin-left: 0px !important;
        max-width: 220px;
        margin-bottom: 10px;
      }
      button.refund,
      button.receipt,
      button.user-comm-button {
        margin-right: 30px !important;
      }
    }
  }
  .assign-driver-section {
    display: block;
    .section {
      display: block;
      button {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
  .order-details-container {
    margin-left: 6px !important;
    margin-top: -10px !important;
    .basic-order-details {
      flex-direction: column !important;
      padding: 8px 15px;
      .detail {
        margin-right: 30px !important;
        margin-bottom: 15px;
      }
    }
    .client-details-section {
      flex-direction: column !important;
      .single-section {
        width: 100%;
        &:last-child {
          border-top: solid 1px rgba(0, 0, 0, 0.2);
          width: 100%;
          align-items: flex-start !important;
          margin-top: 8px;
          padding-top: 8px;
        }
      }
    }
    .order-details-section {
      .single-plate {
        .basic-plate-details {
          .dish-count {
            width: 20px !important;
            margin-right: 10px !important;
          }
        }
      }
    }
    .driver-details-section {
      flex-direction: column;
      padding: 8px 0px !important;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      padding: 15px 0px;
      width: 100%;
      .driver-details-fields-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        > div {
          width: 100%;
        }
        > .driver-name-and-date-container {
          padding-bottom: 8px;
        }
        > .driver-phone-and-earnings-container {
          align-items: flex-start !important;
          padding: 10px 10px 0;
          border-top: solid 1px rgba(0, 0, 0, 0.2);
        }
      }
    }
    .review-details-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px 0px;
      border-bottom: solid 3px rgba(0, 0, 0, 0.6);
      .label {
        margin-bottom: 10px;
      }
      .single-review {
        justify-content: center;
        flex-direction: column;
        img {
          margin-right: 12px;
          height: 35px !important;
        }
        .reviewee-details {
          height: fit-content;
        }
        .review-rating {
          margin-left: 40px;
          margin-top: -8px;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .toll-section {
      .header {
        flex-direction: row !important;
        .label {
          width: fit-content;
        }
        button {
          font-size: 14px;
        }
      }
    }
    .transaction-info-section {
      .transaction-section {
        .transaction-type {
          margin-bottom: 10px;
        }
        .transaction-type,
        .transaction-id {
          flex-direction: column;
          span:first-child {
            opacity: 0.5;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
          }
          span:last-child {
            font-size: 18px;
            line-height: 22px;
          }
          &:last-child {
            word-break: break-all;
            width: 80%;
          }
        }
        .card-details {
          .card-number,
          .card-expiry {
            flex-direction: column;
            margin-bottom: 10px;
            span:first-child {
              opacity: 0.5;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;
            }
            span:last-child {
              font-size: 18px;
              line-height: 22px;
            }
          }
          .card-number {
            div:first-of-type {
              flex-direction: row-reverse;
              justify-content: flex-end;
              > img {
                margin-left: 10px !important;
              }
              > span {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
    .notes-section {
      button {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header {
    flex-direction: column !important;
    height: fit-content !important;
    .buttons-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 15px;
      button.refund,
      button.receipt,
      button.user-comm-button,
      button.finished {
        padding: 0 15px !important;
        margin-left: 0px !important;
        margin-bottom: 10px;
      }
      button.refund,
      button.receipt,
      button.user-comm-button {
        margin-right: 30px !important;
      }
    }
  }
  .order-details-container {
    margin-top: -10px !important;
    .basic-order-details {
      flex-wrap: wrap !important;
      .detail {
        margin-right: 30px !important;
        margin-bottom: 15px;
      }
    }
    .client-details-section {
      .single-section {
        width: 65%;
        &:last-child {
          width: 35%;
        }
      }
    }
    .toll-section {
      .header {
        flex-direction: row !important;
        .label {
          width: fit-content;
        }
        button {
          width: 120px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    flex-direction: column !important;
    height: fit-content !important;
    .buttons-container {
      display: flex;
      margin-top: 15px;
      button.refund,
      button.receipt,
      button.user-comm-button,
      button.finished {
        padding: 0 20px !important;
        margin-left: 20px !important;
      }
      button.refund {
        margin-left: 0 !important;
      }
    }
  }
  .order-details-container {
    .basic-order-details {
      flex-wrap: wrap !important;
      .detail {
        margin-right: 30px !important;
        margin-bottom: 15px;
      }
    }
    .client-details-section {
      .single-section {
        width: 75%;
        &:last-child {
          width: 25%;
        }
      }
    }
    .toll-section {
      .header {
        flex-direction: row !important;
        .label {
          width: fit-content;
        }
        button {
          width: 120px;
        }
      }
    }
  }
}
