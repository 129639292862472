.leadFilter {
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
  flex-wrap: wrap;
  height: auto;
  max-width: 100% !important;
    .select-container {
      select {
        max-width: 174px;
        margin-right: 1.5rem !important;
        height: 40px;
      }
    }
    input {
      padding-left: 10px !important;
      padding-right: 10px !important;
      max-width: 174px;
      height: 40px
    }
  .button-container {
    width: 10%;
    display: flex;
    align-items: flex-end;
    padding-top: 25px;
    button {
    }
  }
}