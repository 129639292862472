.loading {
    .loader-cover {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .page-loader {
        position: fixed;
        top: calc(50% - 5rem);
        left: calc(50% - 5rem);
        width: 5rem;
        height: 5rem;
        z-index: 1000;
    }
}
