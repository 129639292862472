.sidenav {
  background-color: #132632;
  top: 0px;
  bottom: 0px;
  width: 240px;
  left: -240px;
  overflow: hidden;
  z-index: 2;
  transition: left 0.3s ease-out 0s;
  &.open {
    left: 0px;
  }
  >.logo > img {
    height: 55px;
  }
  .profile {
    left: 0px;
    right: 0px;
    bottom: 10px;
    color: #FFFFFF;

    .avatar-text {
      background-color: #FFFFFF;
      color: #1C3A4D;
      font-weight: bold;
      height: 35px;
      width: 35px;
      border-radius: 35px;
    }
    .media {
      width: 182px;

      .media-body {
        font-size: 14px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #1C3A4D;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #F4511D;

  }
  ::-webkit-scrollbar-thumb:hover {
    background: #F4511D;
  }
}
.toggle-button {
  height: 35px;
  width: 35px;
  cursor: pointer;
  left: 0px;
  z-index: 3;
  background-color: #010101;
  color: #FFFFFF;
  transition: left 0.3s ease-out 0s;
  &.open {
    left: 205px;
    background-color: #FFFFFF;
    color: #010101;
  }
}

@media (max-width: 575px) {
  .toggle-button {
    left: 0px;
    top: 0;
  }
}

@media (max-width: 768px) {
  .sidenav {
    width: -100% !important;
    &.open {
      width: 100% !important;
    }
  }
  .toggle-button {
    left: 0px;
    &.open {
      left: calc(100% - 35px);
    }
  }
}

.menu {
  max-height: 65%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}