.notes-main-container {
  padding-left: 7%;
  .heading-container {
    display: flex;;
    flex-direction: row;
    width: 100%;
    padding: 0 4% 0;
  }
  .notes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    padding: 5% 7%;
    .notes-list {
      overflow: auto;
      max-height: 350px;
      .single-note {
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.1);
        padding: 12px 7px 5px;
        .note-content {
          overflow: hidden;
          margin-bottom: 5px;
          line-height: 14px;
          font-size: 12px;
        }
        .note-footer {
          display: flex;
          .note-creator {
            margin-top: 4px;
            line-height: 13px;
            font: {
              size: 11px;
              weight: bold;
            }
            color: #F4511D;
          }
          .note-created-at {
            font-size: 11px;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.5);
            margin-top: 3px;
            margin-left: 5px;
          }
          i {
            margin-left: auto;
            background-color: #F4511D;
            border-radius: 50%;
            color: #fff;
            padding: 4px 5px 6px 5px;
            font-size: 8px;
            transition: transform ease-in-out 0.2s;
          }
        }
      }
      .single-note:last-child {
        border-bottom: none;
      }
      .emptylist-message {
        margin-top: 10px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .add-note-section {
      margin-top: 30px;
      textarea {
        font-size: 14px;
        line-height: 18px;
        resize: none;
      }
      button {
        margin-top: 10px;
        margin-left: auto;
        min-width: 100px;
      }
    }
  }
}
.collapsed {
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  i.fa-chevron-up {
    transform: rotate(180deg);
  }
}