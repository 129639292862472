.side-nav-item {
  background-color: #1C3A4D;
  height: 40px;
  width: 220px;
  border-radius: 6px;
  color: #FFFFFF;
  margin: 8px auto;
  cursor: pointer;
  span {
    padding-left: 10px;
  }
  &.active {
    border: 2px solid #F4511D;
  }

  &.red-background {
    border: 0px;
    background-color: #AA3434;
  }
}
i.fa-chevron-down {
  color: #FFF; 
  transition: transform 0.4s;
}
.invert {
  transform: rotateX(180deg);
}