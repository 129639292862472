.container {
  min-width: 93%;
  padding-left: 25px;
}
.email-input {
  min-width: 25px !important;
  max-width: 45% !important;
}
.header-container {
  margin-top: 6px;
  margin-bottom: 10px;
  .picker-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 30%;
    span {
      margin-left: auto;
      margin-right: 10px;
      line-height: 35px;
      font: {
        size: 16px;
        weight: 500;
      }
    }
    select {
      width: 60%;
    }
  }
}
.details-section {
  width: 70%;
  .leadDetail {
    padding: 10px;
    padding-left: 15px;
    border-width: 2px;
    box-shadow: 5px 10px 18px #888888;
    position: relative;
    z-index: 10;

    .docContainer {
      margin: 10px 20px;
      position: relative;

      .fullscreen-icon {
        position: absolute;
        right: -25px;
        top: -25px;
        background-color: #f4511d;
        border-radius: 50%;
        padding: 10px;
        font-size: 14px;
      }

      .approve-icon {
        position: absolute;
        left: -20px;
        top: -20px;
        background-color: #28a745;
        border-radius: 50%;
        padding: 5px;
        font-size: 12px;
      }
    }

    .docImage {
      width: 200px;
      max-height: 200px;
    }

    .labelActions {
      margin: 0;
    }

    .fa {
      margin: 10px;
    }

    .flex {
      flex: 1;
    }

    .pointer {
      cursor: pointer;
    }

    p {
      margin-bottom: 0;
    }

    label {
      font-weight: bold;
      margin-bottom: 0;
      color: #6c757d;
    }

    hr {
      margin-left: -15px;
      margin-right: -10px;
    }
  }

  .lead-information,
  .user-detail,
  .no-user {
    .card {
      border: 0px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    }
    .label {
      color: #6c757d;
      margin-top: 5px;
    }
    .info {
      color: #f4511d;
      font-size: 18px;
      margin-top: -5px;
    }
    .convert-to-driver {
      button {
        color: #000000;
        text-decoration: underline;
      }
    }
    .user-detail-section {
      width: 60%;
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
      .alert-danger {
        margin-top: 10px !important;
        margin-bottom: 0px;
      }
    }
    .resend-email-helper {
      font-size: 12px;
      width: 96%;
      margin-left: 5px;
    }
    .buttons-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: auto;
      padding-right: 25px;
      align-items: flex-end !important;
      button {
        min-width: 90%;
      }
      .resend-email-helper {
        margin-top: 7px;
        margin-bottom: auto;
        width: 90%;
      }
    }
  }
}

.interested-section,
.declined-section {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 93%;
  padding: 12px 4px;
  span {
    text-align: left;
    color: #6c757d;
    line-height: 25px;
  }
  select {
    cursor: pointer;
  }
}
.lead-contacted-btn,
.resend-mail-btn {
  min-width: 90% !important;
  margin: 15px 0px !important;
}
div.d-inline-block > .resend-email-helper {
  margin-top: -10px;
}

.activities-notes-section {
  width: 30%;
  .custom-scrollbar {
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 20px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.3);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}
