.import-container {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.import-container form {
  min-width: 500px;
}

.import-checkbox {
  padding-left: 0;
  input[type=checkbox] {
    appearance: none;
    margin-right: 5px;
    max-height: 22px !important;
    min-height: 22px !important;
    min-width: 22px !important;
    border: solid 1px #F4511D;
    border-radius: 2px;
    padding-left: 5px;
    padding-bottom: 15px;
    line-height: 20px;
  }
  input[type=checkbox]:checked {
    background-color: #F4511D;
  }
  input[type=checkbox]:checked::after {
    content: '\2714';
    line-height: 10px;
    font-size: 15px;
    color: #fff;
  }
}

.cancel {
  background-color: #fafafa !important;
  color: #525252 !important;
  border: 1px solid #d0d0d0 !important;

  &:hover {
    background-color: #fff !important;
  }
}