.activity-log-container {
  padding-left: 7%;
  .heading-container {
    display: flex;;
    flex-direction: row;
    width: 100%;
    padding: 0 4% 0;
  }
  .logs-container {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;
    min-height: 100px;
    max-height: 367px;
    padding: 8% 7%;
    overflow: auto;
    .loading-container {
      display: flex;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
    }
    .single-log {
      padding: 12px 7px 5px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.1);
      .log-content {
        font-size: 12px;
        line-height: 14px;
        span {
          font-weight: bold;
        }
      }
      .log-time {
        font-size: 11px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 5px;
      }
    }
    .single-log:last-child {
      border-bottom: none;
    }
    .empty-logs-text {
      color: rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}