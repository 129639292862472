.list-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 10px;
  .list-heading {
    font-size: 28px;
  }
  button {
    width: 180px;
    margin: auto 0 auto auto !important;
  }
}