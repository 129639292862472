.reject-modal-container {
  display: none;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2;

  .modal-box {
    width: 60%;
    height: 60%;
    min-height: 250px;
    background-color: #FFFFFF;
    border-radius: 6px;
    text-align: left;

    .close-button {
      float: right;
      padding: 5px 10px;
      cursor: pointer;
    }
    textarea {
      height: 100px;
      resize: none;
    }
  }
  &.show {
    display: flex;
  }
}
