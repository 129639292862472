.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #F3F3F3;
  .heading {
    font-size: 32px;
    line-height: 42px;
    color: #161616;
    margin-top: 10px;
  }
  button {
    margin-top: 60px;
    margin-bottom: 10px;
    align-items: center;
    span {
      margin-left: 35px;
    }
  }
}
.filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 15px;
  flex-wrap: wrap;
  select, input {
    width: 220px;
  }
  .autosuggest-input {
    width: 220px;
    margin: 0 !important;
    border: 1px solid #ced4da;
  }
  .buttons-container {
    display: flex;
    margin-left: auto;
    button {
      margin-top: 10px !important;
    }
    & :first-child {
      background-color: #F1F1F1 !important;
      border: 1px solid #ced4da !important;
      color:  #525252 !important;
      &:hover {
        background-color: #F8F8F8 !important;
      }
    }
    & :last-child {
      margin-left: 25px !important;
    }
  }
}

.search-box { 
  min-width: 250px !important;
  max-width: 250px !important;
  margin-bottom: 10px;
  padding-left: 10px;
}
.input-group {
  @extend .checkbox-style;
  margin-right: 30px;
  z-index: 1;
}
.autosuggest-group {
  @extend .input-group;
  z-index: 2;
  input {
    height: 43px !important;
  }
}
.label {
  font-size: 12px;
  line-height: 16px;
  color: #525252;
}
.checkbox-group {
  @extend .input-group;
  display: flex;
  height: 45px;
  align-items: flex-end !important;
}
.checkbox-label {
  margin-left: 10px;
  font-size: 18px;
  color: #525252;
}
.checkbox-style {
  input[type=checkbox] {
    appearance: none;
    min-height: 25px;
    max-height: 25px;
    min-width: 25px;
    max-width: 25px;
    outline: none;
    border-radius: 0;
    background-color: #FFF !important;
    border: 1px solid #B1B1B1;
    cursor: pointer;
    &:checked {
      background-color: #FF3d00 !important;
      padding: 4px 0 5px 7px !important;
      border: 0px !important;
    }
    &:checked::after {
      color: #fff;
      content: '\2714';
    }
    &:disabled {
    justify-content: space-between;
      background-color: #8D8D8D !important;
    }
  }
}