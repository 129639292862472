.route-header {
  padding: 0 0 20px;
  .route-heading {
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
  }
  div.back-button {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }
}
.basic-route-details-container {
  display: flex;
  padding: 20px 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  align-items: center;
  .info-group:last-child {
    margin-right: 0 !important;
  }
 }
.driver-details-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  width: 100%;
  .single-section:first-child {
    width: 50%;
    .driver-name-container {
      display: flex;
      align-items: center;
      margin-top: 15px;
      div > i {
        background-color: rgb(207, 203, 203);
        font-size: 15px;
        padding: 10px 9px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .driver-name {
        font-size: 20px;
        line-height: 23px;
        text-decoration-line: underline;
        @extend .theme-orange;
      }
      .driver-joined {
        font-size: 16px;
        line-height: 19px;
        color: #6C7880;
      }
    }
  }
  .single-section:last-child {
    width: 50%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    > button {
      min-width: 180px;
    }
    .driver-phone, .driver-earnings {
      font-size: 20px;
      line-height: 23px;
      font-weight: bold;
    }
    .driver-earnings {
      letter-spacing: 0.5px;
    }
    .driver-phone {
      margin-bottom: 15px;
    }
  }
}
.assign-driver-section{
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  padding: 20px 10px;
  width: 100%;
  .section {
    display: flex;
    margin-top: 8px;
    button {
      margin-left: 20px;
      min-width: 75px;
    }
  }
}
.map-container {
  padding: 20px 10px;
  .map {
    border-radius: 10px;
  }
}
.marker {
  position: relative;
  z-index: 1;
  > div:nth-of-type(1) {
    display: flex;
    justify-content: center;
    >.marker-number {
      position: absolute;
      font-size: 15.9867px;
      line-height: 19px;
      color: #FFF;
      top: 15%;
    }
  }
}
.activites-section {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin-top: 40px;
}
.activity-log {
  display: flex;
  padding: 20px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}
.activity-indicator {
  width: 30px;
  height: 86px;
  .activity-marker {
    background-color: firebrick;
    height: 60px;
    width: fit-content;
    position: relative;
    img.line {
      position: absolute;
      left: 12.5px;
    }
    .circle {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 45%;
      >.circle-image {
        position: relative;
      }
      >.activity-marker-number {
        position: absolute;
        top: 3px;
        color: #FFF;
      }
    }
  }
}
.activity-details {
  margin-left: 30px;
  width: 75%;
  i {
    font-size: 7px;
    vertical-align: middle;
  }
  .start-activity {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 24px;
    line-height: 28px;
  }
  .activity-info {
    display: flex;
    font-size: 14px;
    line-height: 16px;
  }
  .activity-address {
    margin-top: 10px;
    width: 60%;
    font-size: 24px;
    line-height: 28px;
  }
}
.activity-stats {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 20%;
  .activity-status {
    color: #FFF;
    width: fit-content;
    height: fit-content;
    padding: 5px 12px;
    border-radius: 10px;
    font-size: 13px;
    line-height: 15px;
  }
  .chevron {
    cursor: pointer;
    background-color: #F4511D;
    font-size: 12px;
    color: #FFF;
    padding: 4px 8px;
    border-radius: 50%;
  }
}
.stop-tooltip {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  .stop-info-group:last-child {
    max-width: 250px;
  }
}
.column-section:first-child {
  margin-right: 60px;
}
.stop-info-group {
  color: #FFF !important;
  margin-bottom: 15px;
  & :first-child {
    font-size: 12px;
    line-height: 16px;
  }
  & :last-child {
    font-size: 20px;
    line-height: 23px;
  }
}
.route-updates-section {
  align-items: center;
  padding: 20px 10px;
  width: 100%;
  >.label {
    margin-bottom: 15px;
  }
}
.info-group  {
  margin-right: 5%;
}
.label {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  opacity: 0.5;
}
.detail {
  font-size: 20px;
  line-height: 23px;
}
.theme-orange {
  color: #F4511D;
}
.route-status {
  padding: 8px 22px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  border-radius: 10px;
}
.start, .pickupShipment {
  background-color: #FFC107 !important;
}
.deliverShipment {
  background-color: #0E9E35 !important;
}
.ASSIGNED {
  background-color: #A2E1FF !important;
  color: #008ACB !important;
}
.PENDING, .PARTIALLY_DELIVERED {
  @extend .pickupShipment;
  color: #FFF !important;
}
.DELIVERED {
  background: rgba(14, 158, 53, 0.2);
  color: #0E9E35 !important;
}