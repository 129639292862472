.message {
  font-size: 14px;
  padding: 5px 15px;
}
.alert, .message {
  text-align: center;
}
.empty-section-message {
  margin: 20px 0;
  padding: 20px;
  background-color: rgba($color: #000000, $alpha: 0.12);
  color: rgba($color: #000000, $alpha: 0.5);
  text-align: center;
}