.app {
  .main {
    padding: 10px;

    .main-content {
      padding: 20px 10px 40px;
      position: fixed;
      // top: 10px;
      bottom: 0px;
      right: 0px;
      overflow: scroll;
      height: 100vh;
      transition: all 0.3s ease-out 0s;

      &.menu-close {
        left: 0px;
        padding-left: 40px;
      }

      &.menu-open {
        //transition: all 0.3s ease-out 0s;
        left: 240px;

      }
    }
  }
}

.btn-primary {
  background-color: #F4511D;
  border-color: #F4511D !important;
  transition: opacity ease-in-out 0.12s;
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):hover {
    background-color: #F4511D !important;
    border-color: #F4511D !important;
    opacity: 0.8;
  }
  &:disabled {
    background-color: #F4511D;
    cursor: default;
  }
  &:focus {
    background-color: #F4511D;
    box-shadow: none !important;
  }
}
.btn-remove {
  @extend .btn-primary;
  color: #FFF;
  background-color: #CC1818;
  border-color: #CC1818 !important;
  &:hover {
    background-color: #F14646;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):hover {
    background-color: #CC1818 !important;
    border-color: #CC1818 !important;
    color: #FFF;
    opacity: 0.7;
  }
  &:disabled {
    background-color: #CC1818;
  }
  &:focus {
    background-color: #CC1818;
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
}
input[type=checkbox] {
    appearance: none;
    border: solid 1px #F4511D;
    border-radius: 2px;
    max-height: 22px !important;
    min-height: 22px !important;
    min-width: 22px !important;
    padding-left: 2px;
    cursor: pointer;
  }
input[type=checkbox]:checked {
  background-color: #F4511D;
}
input[type=checkbox]:checked::after {
  content: '\2714';
  color: #fff;
  font-size: 20px;
  line-height: 1px;
}
input[type=checkbox]:disabled {
  border: #afafaf;
  background-color: #afafaf;
}
@media (max-width: 575px) {
  .main {
    height: 35px;
    border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
  }
  .main-content {
    &.menu-close {
      top: 35px !important;
      padding-left: 0px !important;
    }
  }
}

@media (max-width: 768px) {
  .main-content {
    &.menu-open {
      width: 100% !important;
      position: fixed !important;
      left: 0px !important;
    }
  }
}
